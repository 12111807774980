<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="userData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <user-edit-tab-account
          :user-data="userData"
          class="mt-2 pt-75"
          @closem="closem"
        />
      </b-tab>

      <!-- Tab: Permissions -->
      <!-- <b-tab v-if="userData.uid">
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Permissions</span>
        </template>
        <user-edit-tab-permissions :user-data="userData" class="mt-2 pt-75" />
      </b-tab> -->

      <!-- Tab: Payin Merchants Affiliate -->
      <b-tab v-if="userData.uid" lazy>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Payin Merchants</span>
        </template>
        <payin-affiliated-merchants :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Payout Merchants Affiliate -->
      <b-tab v-if="userData.uid" lazy>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Payout Merchants</span>
        </template>
        <payout-affiliated-merchants :user-data="userData" class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import UserEditTabAccount from './UserEditTabAccount.vue'
import PayinAffiliatedMerchants from './PayinAffiliatedMerchants.vue'
import PayoutAffiliatedMerchants from './PayoutAffiliatedMerchants.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    PayinAffiliatedMerchants,
    PayoutAffiliatedMerchants,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closem(value) {
      this.$emit('closem', value)
    },
  },
}
</script>
