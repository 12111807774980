<template>
  <div>
    <b-modal
      :visible="isEditModalActive"
      title="User Detail"
      size="xl"
      hide-footer
      centered
      :no-close-on-backdrop="true"
      @change="(val) => isEditModalActive = val"
    >
      <user-edit :user-data="userData" @closem="closem" />
    </b-modal>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              @click="addNewUser"
            >
              <span class="text-nowrap">Add User</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        :show="isLoading"
        spinner-variant="primary"
        variant="light"
        opacity="0.7"
        rounded="sm"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="users"
          :per-page="perPage"
          :current-page="currentPage"
          responsive
          :fields="tableColumns"
          primary-key="reseller_id"
          show-empty
          empty-text="No matching records found"
          :filter="searchQuery"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <!-- Column: User -->
          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="data.item.reseller_name"
                />
              </template>
              <b-link
                class="font-weight-bold d-block text-nowrap"
                @click="editUser(data.item)"
              >
                {{ data.item.reseller_name }}
              </b-link>
              <small class="text-muted">@{{ data.item.reseller_id }}</small>
            </b-media>
          </template>

          <!-- Column: Email -->
          <template #cell(email)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.email }}</span>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ resolveUserStatusText(data.item.status) }}
            </b-badge>
          </template>

          <!-- Column: action -->
          <template #cell(action)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="data.item.status === 1 ? 'PauseIcon' : 'PlayIcon'"
                size="16"
                class="cursor-pointer"
                @click="toggleResellerStatus(data.item.reseller_id)"
              />
              <!-- <feather-icon
                icon="Trash2Icon"
                size="16"
                class="cursor-pointer mx-1"
                @click="deleteConfirm(data.item)"
              /> -->
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BMedia, BAvatar, BLink, BFormInput,
  BBadge, BPagination, BOverlay,
} from 'bootstrap-vue'
import { showToast } from '@/libs/tool'
import store from '@/store'
import UserEdit from './user-edit/UserEdit.vue'
import useUsersJs from './users'

const {
  fetchResellerUsers,
  fetchUpperResellers,
  fetchCountryOptions,
  deleteResellerUser,
  toggleResellerUserStatus,
} = useUsersJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BOverlay,

    UserEdit,
  },
  data() {
    return {
      tableColumns: [
        'user',
        'email',
        'country',
        { key: 'upper_reseller_id', label: 'Upper' },
        'type',
        'kyc_status',
        'status',
        'expiration_time',
        'action',
      ],
      currentPage: 1,
      perPage: 10,
      totalUsers: 0,
      filterOn: ['reseller_id', 'reseller_name', 'uid', 'email', 'phone'],
      searchQuery: '',
      isEditModalActive: false,
      selected: [],
      tableFields: [
        'module',
        'opt',
      ],
      options: [
        { text: 'Read', value: 'read' },
        { text: 'Write', value: 'write' },
        { text: 'Create', value: 'create' },
        { text: 'Delete', value: 'delete' },
      ],
      userData: {
        uid: '',
        avatar: '',
        fullName: '',
        email: '',
        phone: '',
        country: '',
      },
      upperResellers: [],
      isLoading: false,
      countryOptions: [],
      kycStatus: '',
    }
  },
  computed: {
    dataMeta() {
      return {
        from: this.perPage * (this.currentPage - 1) + (this.totalUsers ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.totalUsers,
        of: this.totalUsers,
      }
    },
    isSaveDisabled() {
      return this.userData.email === '' || this.userData.phone === '' || this.userData.fullName === ''
    },
    users() {
      return store.state.user.resellers
    },
  },
  created() {
    this.fetchUsers()
    // this.fetchOptions()
  },
  methods: {
    inputImageRenderer(input) {
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size / 1024 > 64) {
          this.$bvToast.toast('File is too large, Please control the file size within 64k.', {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'warning',
            toaster: 'b-toaster-top-center',
            solid: true,
          })
          return
        }
        const reader = new FileReader()
        reader.onload = e => {
          this.userData.avatar = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    addNewUser() {
      this.isEditModalActive = true
      this.userData = {
        reseller_id: '',
        reseller_name: '',
        avatar: '',
        email: '',
        phone: '',
        upper_reseller_id: '',
        uid: '',
        type: '',
        country: '',
      }
    },
    editUser(user) {
      this.userData = {
        reseller_id: user.reseller_id,
        upper_reseller_id: user.upper_reseller_id,
        reseller_name: user.reseller_name,
        avatar: user.avatar,
        email: user.email,
        phone: user.phone,
        uid: user.uid,
        permissions: user.permissions,
        resources: user.resources,
        detail: user.detail,
        type: user.type,
        country: user.country,
      }
      this.isEditModalActive = true
      this.kycStatus = user.kyc_status
    },
    fetchUsers() {
      const that = this
      that.isLoading = true
      fetchResellerUsers(
        list => {
          that.isLoading = false
          that.totalUsers = list.length
        },
        fail => {
          that.isLoading = false
          showToast(that, 'Warning', `fetch Users fail with ${fail}.`)
        },
      )
      // 获取 Upper Resellers
      fetchUpperResellers(
        list => {
          this.upperResellers = []
          Object.keys(list).forEach(item => {
            const resellerObject = {}
            resellerObject.label = list[item]
            resellerObject.value = item
            this.upperResellers.push(resellerObject)
          })
        },
        fail => {
          showToast(this, 'Warning', `fetch Upper Resellers fail with ${fail}.`)
        },
      )
      fetchCountryOptions(
        data => {
          const { countries } = data
          this.countryOptions = countries.map(item => ({ label: item.region_name, value: item.region_name }))
        },
        fail => {
          showToast(this, 'Warning', `fetch Country fail with ${fail}.`)
        },
      )
    },
    onFiltered(filteredItems) {
      this.totalUsers = filteredItems.length
      this.currentPage = 1
    },
    resolveUserStatusVariant(status) {
      if (status === 1) return 'success'
      if (status === 0) return 'warning'
      return 'primary'
    },
    resolveUserStatusText(status) {
      if (status === 1) return 'Enabled'
      if (status === 0) return 'Disabled'
      return 'Other'
    },
    toggleResellerStatus(resellerId) {
      toggleResellerUserStatus(
        resellerId,
        () => {
          showToast(this, 'Success', 'User Updated')
          this.fetchUsers()
        },
        fail => {
          showToast(this, 'Warning', `User Update fail with ${fail}.`)
        },
      )
    },
    deleteConfirm(user) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteUser(user)
        }
      })
    },
    deleteUser(user) {
      deleteResellerUser(
        user.reseller_id,
        () => {
          showToast(this, 'Success', 'User Deleted')
          this.fetchUsers()
        },
        fail => {
          showToast(this, 'Warning', `User Delete fail with ${fail}.`)
        },
      )
    },
    closem(value) {
      this.isEditModalActive = value
    },
  },
  provide() {
    return {
      getUpperResellers: () => this.upperResellers,
      getCountryOptions: () => this.countryOptions,
      getKycStatus: () => this.kycStatus,
    }
  },

}
</script>
