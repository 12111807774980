import store from '@/store'
import merchantStoreModule from '@/pagsmile/merchantStoreModule'
import userStoreModule from '../../userStoreModule'

export default function usersJs() {
  const USER_STORE_MODULE_NAME = 'user'
  const MERCHANT_STORE_MODULE_NAME = 'merchant'

  // Register module
  if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)

  const fetchResellerUsers = (success, fail) => {
    store
      .dispatch('user/fetchResellerUsers')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          store.commit('user/SET_RESELLERS', data)
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchUpperResellers = (success, fail) => {
    store
      .dispatch('user/fetchUpperResellers')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchCountryOptions = (success, fail) => {
    store
      .dispatch('user/fetchCountryOptions')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updateResellerUser = (params, success, fail) => {
    store
      .dispatch('user/updateResellerUser', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const resellerChangePassword = (params, success, fail) => {
    store
      .dispatch('user/resellerChangePassword', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const createResellerUser = (params, success, fail) => {
    store
      .dispatch('user/createResellerUser', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const deleteResellerUser = (params, success, fail) => {
    store
      .dispatch('user/deleteResellerUser', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const toggleResellerUserStatus = (params, success, fail) => {
    store
      .dispatch('user/toggleResellerUserStatus', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchants = (success, fail) => {
    store
      .dispatch('merchant/fetchDropDownMerchants')
      .then(response => {
        const { code, data } = response.data

        const merchants = []
        if (code === '10000') {
          data.forEach(item => {
            if (item.merchant_no === 'All') {
              return
            }
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })
        }

        success(merchants)
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchPayoutMerchants = (success, fail) => {
    store
      .dispatch('merchant/fetchPayoutMerchants')
      .then(response => {
        const { code, data } = response.data

        const merchants = []
        if (code === '10000') {
          data.forEach(item => {
            if (item.merchant_no === 'All') {
              return
            }
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })
        }

        success(merchants)
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchantApps = (merchantNo, success, fail) => {
    store
      .dispatch('merchant/fetchMerchantApps', merchantNo)
      .then(response => {
        const { code, data } = response.data

        const apps = [{ label: 'All', value: '' }]
        if (code === '10000') {
          data.forEach(item => {
            const app = { label: item.app_name, value: item.app_id }
            apps.push(app)
          })
        }
        success(apps)
      })
      .catch(() => {
        fail('error')
      })
  }

  const listResellerMerchants = (resellerId, success, fail) => {
    store
      .dispatch('user/listResellerMerchants', resellerId)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const addPayinResellerMerchant = (params, success, fail) => {
    store
      .dispatch('user/addPayinResellerMerchant', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const addPayoutResellerMerchant = (params, success, fail) => {
    store
      .dispatch('user/addPayoutResellerMerchant', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const delResellerMerchant = (params, success, fail) => {
    store
      .dispatch('user/delResellerMerchant', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const toggleResellerMerchant = (params, success, fail) => {
    store
      .dispatch('user/toggleResellerMerchant', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  return {
    fetchResellerUsers,
    fetchUpperResellers,
    fetchCountryOptions,
    updateResellerUser,
    resellerChangePassword,
    createResellerUser,
    deleteResellerUser,
    toggleResellerUserStatus,
    fetchMerchants,
    fetchPayoutMerchants,
    fetchMerchantApps,
    listResellerMerchants,
    addPayinResellerMerchant,
    addPayoutResellerMerchant,
    delResellerMerchant,
    toggleResellerMerchant,
  }
}
