<template>
  <div>
    <b-media v-if="userData.reseller_id != ''" class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.reseller_name)"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.reseller_name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-link
          :to="{ name: 'reseller-info-detail', query:{reseller_id: userData.reseller_id, from: '' } }"
          target="_blank"
        >
          <!-- variant="secondary" -->
          <b-button
            variant="primary"
            class="ml-1"
            @click="userData.avatar = ''"
          >
            <span class="d-none d-sm-inline">Detail</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </b-link>
        <!-- <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="userData.avatar = ''"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button> -->
      </div>
    </b-media>

    <b-form>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="E-Mail(Login Name)" label-for="login-name">
            <b-form-input id="login-name" v-model="userData.email" :disabled="userData.reseller_id != ''" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Name" label-for="name">
            <b-form-input id="name" v-model="userData.reseller_name" :disabled="userData.reseller_id != ''" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Country" label-for="Country">
            <v-select
              id="Country"
              v-model="userData.country"
              :options="countryOptions"
              :clearable="false"
              :reduce="val => val.value"
              :disabled="userData.reseller_id != '' && kycStatus === 'PASS'"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Phone" label-for="phone">
            <b-form-input id="phone" v-model="userData.phone" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Upper Reseller" label-for="upper-reseller">
            <!-- :searchable="false" -->
            <v-select
              v-model="userData.upper_reseller_id"
              :options="upperResellers"
              :clearable="false"
              :reduce="val => val.value"
              input-id="upper-reseller"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Type" label-for="type">
            <v-select
              v-model="userData.type"
              :options="typeOptions"
              :clearable="false"
              :searchable="false"
              :reduce="val => val"
              input-id="type"
              :disabled="userData.reseller_id != '' && kycStatus === 'PASS'"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>
    <!-- || userData.upper_reseller_id==='' -->
    <b-button
      variant="primary"
      class="mt-2 mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="saving || userData.email === '' || userData.country === '' || userData.reseller_name === '' || userData.phone === '' || userData.type===''"
      @click="save"
    >
      {{ saving ? 'Saving' : 'Save Changes' }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BLink,
  // BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useUsersJs from '../users'

const {
  fetchResellerUsers,
  createResellerUser,
  updateResellerUser,
} = useUsersJs()

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BLink,
    // BInputGroup,
    // BInputGroupPrepend,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      avatarUpdated: false,
      resellers: [],
      typeOptions: ['Entity', 'Individual'],
    }
  },
  computed: {
    upperResellers() {
      return this.getUpperResellers()
    },
    countryOptions() {
      return this.getCountryOptions()
    },
    kycStatus() {
      return this.getKycStatus()
    },
  },
  created() {
    // if (!this.userData.detail) {
    //   return
    // }
  },
  methods: {
    inputImageRenderer(input) {
      // 限制文件格式 .jpg/.jpeg/.jpe/.png
      const filename = input.target.files[0].name
      const fileLen = filename.split('.').length
      const fileType = filename.split('.')[fileLen - 1]
      if (fileType !== 'jpg' && fileType !== 'jpeg' && fileType !== 'jpe' && fileType !== 'png') {
        this.$bvToast.toast('Please upload the correct file format.', {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'warning',
          toaster: 'b-toaster-top-center',
          solid: true,
        })
        return
      }
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size / 1024 > 200) {
          this.$bvToast.toast('File is too large, Please control the file size within 64k.', {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'warning',
            toaster: 'b-toaster-top-center',
            solid: true,
          })
          return
        }
        const reader = new FileReader()
        reader.onload = e => {
          this.avatarUpdated = true
          this.userData.avatar = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    fetchUsers() {
      fetchResellerUsers(
        list => {
          this.totalUsers = list.length
        },
        fail => {
          showToast(this, 'Warning', `fetch Users fail with ${fail}.`)
        },
      )
    },
    save() {
      this.saving = true
      if (!this.userData.reseller_id || this.userData.reseller_id === '') {
        // 新建用户
        createResellerUser(
          this.userData,
          uid => {
            showToast(this, 'Success', `${uid} Created`)
            this.fetchUsers()
            this.saving = false
            this.$emit('closem', false)
          },
          fail => {
            showToast(this, 'Warning', `${fail}.`)
            this.saving = false
          },
        )
      } else {
        console.log(this.userData)
        // 更新权限
        updateResellerUser(
          this.userData,
          () => {
            showToast(this, 'Success', 'Updated')
            this.fetchUsers()
            this.saving = false
          },
          fail => {
            showToast(this, 'Warning', `Update Permissions Fail with ${fail}.`)
            this.saving = false
          },
        )
      }
    },
    avatarText,
  },
  inject: ['getUpperResellers', 'getCountryOptions', 'getKycStatus'],
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
