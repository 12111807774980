import axios from '@axios'

export default {
  namespaced: true,
  state: {
    users: [],
    resellers: [],
  },
  getters: {
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users
    },
    SET_RESELLERS(state, resellers) {
      state.resellers = resellers
    },
  },
  actions: {
    // 获取详细
    fetchAdminUsers() {
      return new Promise((resolve, reject) => {
        axios.post('/user/list')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 更新权限
    updateAdminUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/user/update?update_uid=${params.uid}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 创建用户
    createAdminUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.put('/user/create', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 删除用户
    deleteAdminUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`/user/delete?del_uid=${params}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 修改密码
    changePassword(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/user/change-password', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // reset mfa
    resetMfa(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios.post(`/user/reset-mfa?reset-uid=${uid}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // reset mfa
    toggleStatus(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios.post(`/user/toggle-status?reset-uid=${uid}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // reset password
    resetPassword(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios.post(`/user/reset-password?reset-uid=${uid}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // Reseller Related

    // 获取详细
    fetchResellerUsers() {
      return new Promise((resolve, reject) => {
        axios.post('/reseller/list')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 获取upper下拉列表
    fetchUpperResellers() {
      return new Promise((resolve, reject) => {
        axios.post('/reseller/list-options')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchCountryOptions() {
      return new Promise((resolve, reject) => {
        axios.post('/reseller/buyin/options')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 更新权限
    updateResellerUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/reseller/update?reseller_id=${params.reseller_id}`, params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 创建用户
    createResellerUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.put('/reseller/create', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 删除用户
    deleteResellerUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`/reseller/delete?reseller_id=${params}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 修改用户状态
    toggleResellerUserStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/reseller/toggle-reseller-status?reseller_id=${params}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 修改密码
    resellerChangePassword(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/reseller/change-password', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // Reseller关联的Merchant列表
    listResellerMerchants(ctx, resellerId) {
      return new Promise((resolve, reject) => {
        axios.post(`/reseller/list-affiliated-merchants-by-reseller?reseller_id=${resellerId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 关联Reseller和Payin Merchant
    addPayinResellerMerchant(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.put(`/reseller/add-payin-affiliate-merchants?reseller_id=${params.reseller_id}&merchant_no=${params.merchant_no}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 关联Reseller和Payout Merchant
    addPayoutResellerMerchant(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.put(`/reseller/add-payout-affiliate-merchants?reseller_id=${params.reseller_id}&merchant_no=${params.merchant_no}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 删除关联Reseller和Merchant
    delResellerMerchant(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`/reseller/remove-affiliated-merchants-by-reseller?reseller_id=${params.reseller_id}&service=${params.service}&merchant_no=${params.merchant_no}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 修改Reseller和Merchant关联状态
    toggleResellerMerchant(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/reseller/toggle-affiliated-merchant-status?reseller_id=${params.reseller_id}&service=${params.service}&merchant_no=${params.merchant_no}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
  },
}
