<template>
  <div>
    <b-overlay :show="tabLoading" rounded="sm">
      <b-form>
        <b-row>
          <b-col cols="12">
            <v-select
              v-model="merchantFilter"
              :options="merchantOptions"
              :clearable="false"
              :searchable="true"
              input-id="user-role"
            />
          </b-col>

          <b-col cols="12" md="12" class="text-center mt-2 mb-2">
            <b-button variant="success" class="btn-icon" @click="addToSelected">
              <feather-icon icon="ChevronsDownIcon" />
            </b-button>
            <!-- <b-button
              variant="danger"
              class="btn-icon ml-2"
              @click="removeFromSelected"
            >
              <feather-icon icon="ChevronsUpIcon" />
            </b-button> -->
          </b-col>

          <b-col v-if="resources && resources.length > 0" cols="12" md="12">
            <b-table
              sticky-header="20rem"
              selectable
              select-mode="multi"
              :items="resources"
              :fields="tableColumns"
              responsive="sm"
              @row-selected="onRowSelected"
            >
              <!-- Column: action -->
              <template #cell(action)="data">
                <div class="text-nowrap">
                  <!-- <feather-icon
                    :icon="data.item.status === 1 ? 'PauseIcon' : 'PlayIcon'"
                    size="16"
                    class="cursor-pointer"
                    @click="toggleResellerMerchantStatus(data.item)"
                  /> -->
                  <b-form-checkbox
                    :checked="data.item.status === 1"
                    name="check-button"
                    switch
                    inline
                    @change="toggleResellerMerchantStatus(data.item)"
                  />
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BTable,
  BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useUsersJs from '../users'

const {
  fetchPayoutMerchants,
  listResellerMerchants,
  addPayoutResellerMerchant,
  delResellerMerchant,
  toggleResellerMerchant,
} = useUsersJs()

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    vSelect,
    BTable,
    BOverlay,
    BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      tabLoading: false,
      merchantFilter: [],
      merchantOptions: [],
      selected: [],
      resources: [],
      tableColumns: [
        { key: 'merchant_name', label: 'Merchant Name' },
        { key: 'merchant_no', label: 'Merchant NO.' },
        'action',
      ],
    }
  },
  created() {
    fetchPayoutMerchants(
      merchants => {
        this.merchantOptions = merchants
      },
      fail => {
        showToast(this, 'Warning', `fetch Merchants fail with ${fail}.`)
      },
    )
    this.fetchReseelerMerchants()
  },
  methods: {
    fetchReseelerMerchants() {
      this.resources = []
      listResellerMerchants(
        this.userData.reseller_id,
        merchants => {
          merchants.forEach(merchant => {
            if (merchant.service !== 'payout') {
              return
            }
            this.resources.push(merchant)
          })
        },
        fail => {
          showToast(
            this,
            'Warning',
            `fetch reseller's merchants fail with ${fail}.`,
          )
        },
      )
    },
    addToSelected() {
      this.tabLoading = true
      const index = this.resources.filter(
        r => r.merchant_no === this.merchantFilter.value,
      )
      if (index.length > 0) {
        return
      }
      const item = {
        merchant_no: this.merchantFilter.value,
        merchant_name: this.merchantFilter.label,
        status: 1,
      }
      const param = {
        reseller_id: this.userData.reseller_id,
        merchant_no: this.merchantFilter.value,
      }
      addPayoutResellerMerchant(
        param,
        () => {
          this.resources.push(item)
          this.tabLoading = false
        },
        fail => {
          showToast(
            this,
            'Warning',
            `add affiliate merchant fail with ${fail}.`,
          )
          this.tabLoading = false
        },
      )
    },
    toggleResellerMerchantStatus(item) {
      const param = {
        reseller_id: this.userData.reseller_id,
        service: 'payout',
        merchant_no: item.merchant_no,
      }
      toggleResellerMerchant(
        param,
        () => {
          this.fetchReseelerMerchants()
          showToast(this, 'Success', 'toggle affiliate merchant success.')
        },
        fail => {
          showToast(
            this,
            'Warning',
            `remove affiliate merchant fail with ${fail}.`,
          )
        },
      )
    },
    removeFromSelected() {
      if (this.selected.length === 0) {
        showToast(this, 'Warning', 'Please select one at least.')
        return
      }
      this.tabLoading = true
      this.selected.forEach(item => {
        const param = {
          reseller_id: this.userData.reseller_id,
          service: 'payout',
          merchant_no: item.merchant_no,
        }
        delResellerMerchant(
          param,
          () => {
            const index = this.resources.findIndex(
              r => r.merchant_no === item.merchant_no,
            )
            this.resources.splice(index, 1)
            this.tabLoading = false
          },
          fail => {
            showToast(
              this,
              'Warning',
              `remove affiliate merchant fail with ${fail}.`,
            )
            this.tabLoading = false
          },
        )
      })
    },
    onRowSelected(items) {
      this.selected = items
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
